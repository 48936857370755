export const PRIMARY = '#BE1F93';
export const PRIMARY_TRANSPARENT = '#ffcaf1';
export const PRIMARY_SOFT = '#B92790';
export const PRIMARY_DARK = '#4D2F82';
export const SECONDARY = '#841A8B';

export const GRAY_PRIMARY = '#DEDEDE';
export const GRAY_SECONDARY = '#B4B4B4';
export const WHITE = '#FFFFFF';
export const WHITE_IMPORTANT = '#FFFFFF !important';

export const RED = '#ff0000';
export const ORANGE = '#FE5D26';
export const GREEN = '#46954D';
export const BLACK = '#000000';

export const PURPLE = '#BE1F93';
export const GRAY_BG = '#ECEFF7';
export const FONT_COLOR = '#ECEFF7';
export const GRAY_LIGHT = '#B1BCDA';
export const TABLE_GRAY = '#F3F4FA';
export const TEXT_COLOR = '#68779F';
export const TEXT_COLOR_ADMIN = '#435179';
export const DISABLED = '#BDBDBD';
export const ERROR = '#FF1744';
export const GREEN_ACTIVE = '#A2E876';
export const SUCCESS = '#238636';
export const GREEN_HIGHLIGHT = '#DBF7E4';
export const BLUE_LINK = '#007BDB';

export const PURPLE_STATUS = '#5F27B9';
export const YELLOW_STATUS = '#F7C537';
export const CYAN_STATUS = '#27A8B9';
export const ORANGE_STATUS = '#F74637';

export const BOX_SHADOW = '#4d2f821a';
export const BORDER_TEXT = '#536777';
export const BORDER_COLOR_ADMIN = '#ECF0FB';
export const GRAY_TITLE = '#B1BCDB';

export const YELLOW_PALE = '#FEF3C7';
export const GREEN_PALE = '#D1FAE5';
export const PURPLE_PALE = '#FCEBF9';

export const ORANGE_ICON = '#F59E0B';
export const GREEN_ICON = '#10B981';

export const RED_STATUS_TABLE = '#FFDFDF';
export const RED_STATUS_TABLE_BORDER = '#D80000';
export const YELLOW_STATUS_TABLE = '#FFF9E2';
export const YELLOW_STATUS_TABLE_BORDER = '#FFCD04';
export const GREEN_STATUS_TABLE = '#EBFFE2';
export const GREEN_STATUS_TABLE_BORDER = '#51C519';
